import {
  debounce,
  distanceBetween2Points,
  angleBetween2Points,
} from '../../utils/helpers'

class Sketcher {
  constructor(canvasID, brush) {
    this.brush = brush
    this.touchSupported = window.Modernizr.touch
    this.firstDrawn = false
    this.canvas = document.getElementById(canvasID)
    this.context = this.canvas && this.canvas.getContext('2d')
    this.lastMousePoint = { x: 0, y: 0 }
    this.oldMousePoint = { x: 0, y: 0 }
    this.mouseDownEvent = this.touchSupported ? 'touchstart' : 'mousedown'
    this.mouseMoveEvent = this.touchSupported ? 'touchmove' : 'mousemove'
    this.mouseUpEvent = this.touchSupported ? 'touchend' : 'mouseup'
  }

  start = () => {
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight

    this.initEventListeners()
    this.setCanvasSize(windowWidth, windowHeight)
    this.updateMousePosition()
    this.updateOldMousePosition()
    this.updateCanvasByBrush()
  }

  initEventListeners = () => {
    if (this.touchSupported) {
      this.canvas.addEventListener(this.mouseDownEvent, this.onCanvasMouseDown())
    } else {
      this.canvas.addEventListener(this.mouseMoveEvent, this.onCanvasMouseMove())
    }

    window.addEventListener('resize', this.onCanvasResize())
  }

  updateCanvasBrush = brush => {
    window.setTimeout(() => {
      this.brush = brush
      this.firstDrawn = false
    }, 0)
  }

  setCanvasSize = (width, height) => {
    if (this.canvas) {
      this.canvas.width = width
      this.canvas.height = height
      this.canvas.style.width = `${width}px`
      this.canvas.style.height = `${height}px`
    }
  }

  onCanvasMouseDown = e => {
    const self = this
    return e => {
      this.mouseMoveHandler = this.onCanvasMouseMove()
      this.mouseUpHandler = this.onCanvasMouseUp()

      document.addEventListener(self.mouseMoveEvent, self.mouseMoveHandler)
      document.addEventListener(self.mouseUpEvent, self.mouseUpHandler)

      this.updateMousePosition(e)
      this.updateCanvasByBrush(e)
    }
  }

  onCanvasMouseUp = e => {
    const self = this

    return e => {
      document.removeEventListener(self.mouseMoveEvent, self.mouseMoveHandler)
      document.removeEventListener(self.mouseUpEvent, self.mouseUpHandler)

      self.mouseMoveHandler = null
      self.mouseUpHandler = null
    }
  }

  onCanvasMouseMove = e => {
    return e => {
      e.preventDefault()
      this.updateMousePosition(e)
      return false
    }
  }

  updateMousePosition = e => {
    let target = {}
    const offset = this.canvas.getBoundingClientRect()
    const halfWindowWidth = window.innerWidth / 2
    const halfWindowHeight = window.innerHeight / 2

    if (this.touchSupported && e) {
      target = e.touches[0] || e.changedTouches[0]

      this.lastMousePoint.x = target ? target.clientX : halfWindowWidth
      this.lastMousePoint.y = target ? target.clientY : halfWindowHeight
    } else {
      target = e

      this.lastMousePoint.x = target
        ? target.clientX - offset.left
        : halfWindowWidth

      this.lastMousePoint.y = target
        ? target.clientY - offset.top
        : halfWindowHeight
    }
  }

  updateOldMousePosition = e => {
    const offset = this.canvas.getBoundingClientRect()
    const halfWindowWidth = window.innerWidth / 2
    const halfWindowHeight = window.innerHeight / 2
    let target = {}

    if (this.touchSupported && e) {
      target = e.touches[0]
    } else {
      target = e
    }

    this.oldMousePoint.x = target ? target.pageX - offset.left : halfWindowWidth
    this.oldMousePoint.y = target ? target.pageY - offset.top : halfWindowHeight
  }

  updateCanvasByBrush = () => {
    const self = this
    const halfBrushW = this.brush.width / 2
    const halfBrushH = this.brush.height / 2

    const start = { x: this.oldMousePoint.x, y: this.oldMousePoint.y }
    const end = { x: this.lastMousePoint.x, y: this.lastMousePoint.y }

    this.oldMousePoint = {
      x: this.lastMousePoint.x,
      y: this.lastMousePoint.y,
    }

    const distance = parseInt(distanceBetween2Points(start, end))
    const angle = angleBetween2Points(start, end)

    for (let z = 0; z <= distance; z++) {
      const x = start.x + Math.sin(angle) * z - halfBrushW
      const y = start.y + Math.cos(angle) * z - halfBrushH

      if (z !== 0 && this.firstDrawn) {
        this.context.drawImage(
          this.brush,
          x,
          y,
          this.brush.width,
          this.brush.height,
        )
      }

      if (!this.firstDrawn) {
        this.context.drawImage(
          this.brush,
          x,
          y,
          this.brush.width,
          this.brush.height,
        )
        this.firstDrawn = true
      }
    }
    this.id = window.requestAnimationFrame(self.updateCanvasByBrush)
  }

  onCanvasResize = () => {
    return debounce(() => {
      const windowWidth = window.innerWidth
      const windowHeight = window.innerHeight
      this.setCanvasSize(windowWidth, windowHeight)
    }, 250)
  }

  clear = () => {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
  }
}

export default Sketcher
