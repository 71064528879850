import React, { useEffect, useState } from 'react'
import { get } from 'lodash-es'
import { withRouter } from 'react-router'
import styled from 'styled-components'
import contentful, { ENTRIES } from '../../services/contentful'
import AppCache from '../../services/cache'
import { mqMax } from '../../utils/mq'

const StyledFooter = styled.div`
  .footer {
    padding: 2rem 0;
    position: fixed;
    transform: translate(-50%);
    left: 50%;
    bottom: 0;
    font-size: 1.1rem;
    letter-spacing: 0;
    ${mqMax(0)`
    display: none;
    `}
  }

  .footer__container {
    text-align: center;
  }

  .footer__item {
    padding: 0 10px;
    a {
      color: #000;
      font-size: 1.25rem;
    }
  }
`

function Footer(props) {
  const [footerLinks, setFooterLinks] = useState([])
  async function fetchFooterLinks() {
    if (!AppCache.get('footerLinks')) {
      const res = await contentful.getEntryById(ENTRIES.footerLinks)
      const data = get(res, 'fields.footerLinks', [])

      setFooterLinks(data)
      AppCache.set('footerLinks', data, 10000)
    } else {
      setFooterLinks(AppCache.get('footerLinks'))
    }
  }

  useEffect(() => {
    fetchFooterLinks()
  }, [])

  if (props.location.pathname === '/') {
    return null
  }

  return (
    <StyledFooter>
      <footer className="footer">
        <div className="footer__container">
          {footerLinks.map(link => {
            return (
              <span className="footer__item" key={get(link, 'fields.title')}>
                <a
                  href={get(link, 'fields.link')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {get(link, 'fields.title')}
                </a>
              </span>
            )
          })}
        </div>
      </footer>
    </StyledFooter>
  )
}

export default withRouter(Footer)
