// contentful service
import { createClient } from 'contentful'

export const ENTRIES = {
  aboutPage: '2HpAt4XTM3UtoVP29d2ftx',
  homePage: '56ZLGIrr3w4cHks2ijKOAc',
  projectsPage: '31ezJJ1H8CR7HVDAMtjygF',
  footerLinks: '4AS6Gl3hptgolgHJIKgVLF',
  infoPage: '2HpAt4XTM3UtoVP29d2ftx',
  previewPage: '6hJlbTPjSeyayvdY3cNbPD',
}

// const getCaseStudyEntryBySlug = slug =>
//   client.getEntries({
//     content_type: 'caseStudy',
//     'fields.slug': slug,
//   })
//
// Define the contentful object to hold configuration
// Default is using preview content for dev & staging
let options = {
  host: 'preview.contentful.com',
  space: process.env.REACT_APP_CTF_SPACE_ID,
  accessToken: process.env.REACT_APP_CTF_CPA_TOKEN,
}

// Switching to use the cdn contentful when on production
if (process.env.NODE_ENV === 'production') {
  options = {
    ...options,
    host: 'cdn.contentful.com',
    accessToken: process.env.REACT_APP_CTF_CDA_TOKEN,
  }
}

const client = createClient(options)

const getEntryById = id => client.getEntry(id)

const getEntriesBy = query => client.getEntries(query)

const getProjectEntries = () =>
  client.getEntries({
    'sys.contentType.sys.id[in]': 'caseStudy,category',
  })

const getEntriesByContentType = contentType =>
  client.getEntries({
    content_type: contentType,
  })

export default {
  client,
  getEntryById,
  getEntriesBy,
  getProjectEntries,
  getEntriesByContentType,
}
