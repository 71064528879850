import React from 'react'
import { Box, Image } from 'reakit'
import get from 'lodash/get'

const getImageSizeUrl = (image, size) => {
  if (image.url.indexOf('.png') < 0 && image.url.indexOf('.gif') < 0) {
    return `${image.url}?w=${size}&q=70`
  }

  if (image.url.indexOf('.png') >= 0) {
    return `${image.url}?w=${size}&q=70`
  }

  if (image.url.indexOf('.gif') >= 0) {
    if (size < 1400) {
      return `${image.url}?w=${size}&q=70`
    }
    return `${image.url}?q=70`
  }

  return image.url
}

const ResponsiveImage = props => {
  const { image } = props

  if (!image || !image.url) {
    return null
  }

  const imageSm = getImageSizeUrl(image, '600')
  const imageMd = getImageSizeUrl(image, '1000')
  const imageLg = getImageSizeUrl(image, '1400')
  const imageXl = getImageSizeUrl(image, '2000')

  return (
    <Box {...props}>
      {get(image, 'url', '').indexOf('.gif') < 0 ? (
        <picture>
          <source srcSet={`${imageXl}&fm=webp`} type="image/webp" />
          <source srcSet={`${imageLg}`} type="image/jpeg" />
          <Image
            src={imageSm}
            srcSet={`${imageMd} 1000w, ${imageLg} 1400w, ${imageXl} 1600w`}
            alt={props.title || image.fileName}
          />
        </picture>
      ) : (
        <picture>
          <Image
            src={imageSm}
            srcSet={`${imageMd} 1000w, ${imageLg} 1400w, ${imageXl} 1600w`}
            alt={props.title || image.fileName}
          />
        </picture>
      )}
    </Box>
  )
}

export default ResponsiveImage
