// Collection of helper functions
export function scrollTo(evt, elmtID) {
  const elmt = document.querySelector(elmtID);

  if (evt && elmt) {
    evt.preventDefault();
    elmt.scrollIntoView({
      behavior: 'smooth',
    });
  }
}

export function getRandomNumberBetween(min, max) {
  return Math.random() * (max - min + 1) + min;
}

export function distanceBetween2Points(point1, point2) {
  var dx = point2.x - point1.x;
  var dy = point2.y - point1.y;
  return Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
}

export function angleBetween2Points(point1, point2) {
  var dx = point2.x - point1.x;
  var dy = point2.y - point1.y;
  return Math.atan2(dx, dy);
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function isMobile() {
  return window.Modernizr.touch;
}

export const getImageSizeUrl = (image, size) => {
  if (image && image.url) {
    if (image.url.indexOf('.png') < 0 && image.url.indexOf('.gif') < 0) {
      return `${image.url}?w=${size}&q=70`;
    }

    if (image.url.indexOf('.png') >= 0) {
      return `${image.url}?w=${size}&q=70`;
    }

    if (image.url.indexOf('.gif') >= 0) {
      if (size < 1400) {
        return `${image.url}?w=${size}&q=70`;
      }
      return `${image.url}?q=70`;
    }

    return image.url;
  }
  return '';
};
