import React from 'react'
import { Link } from 'react-router-dom'
import { get } from 'lodash-es'
import ResponsiveImage from '../../components/ResponsiveImage'
import styled, { css, keyframes } from 'styled-components'
import { addClass, removeClass } from '../../utils/classie'
import { getImageSizeUrl } from '../../utils/helpers'
import { mq } from '../../utils/mq'

const fadeIn1 = keyframes`
  0%{ opacity: 1; transform: translate(10px, 20px); }
  44.99%{ opacity: 1; transform: translate(300px, 100px); }
  45%{ opacity: 0; }
  100%{ opacity: 0; }
`
const fadeIn2 = keyframes`
  0%{ opacity: 0; }
  19.99%{ opacity: 0; }
  20%{ opacity: 1; transform: translate(-30px, 80px); }
  64.99%{ opacity: 1; transform: translate(-200px, -200px); }
  65%{ opacity: 0; }
	100%{ opacity: 0; }
`
const fadeIn3 = keyframes`
  0%{ opacity: 0; }
  39.99%{ opacity: 0;}
  40%{ opacity: 1; transform: translate(65px, -20px); }
  84.99% { opacity: 1; transform: translate(0, 210px); }
  85%{ opacity: 0; }
  100%{ opacity: 0; }
`
const fadeIn4 = keyframes`
  0%{ opacity: 0; }
  59.99%{ opacity: 0; }
  60%{ opacity: 1; transform: translate(-80px, -30px); }
  100%{ opacity: 1; transform: translate(-133px, 16px); }
`
const fadeIn5 = keyframes`
  0%{ opacity: 1; transform: translate(29px, -136px); }
  24.99%{ opacity: 1; transform: translate(40px, -250px); }
  25%{ opacity: 0; }
  79.99%{ opacity: 0; }
  80%{ opacity: 1; transform: translate(20px, -45px); }
  100%{ opacity: 1; transform: translate(29px, -136px); }
`

const StyledImagesLoop = styled.div`
  display: none;
  ${mq(1)`
    display: block;
  `}
  .image-loop-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    z-index: -1;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      height: 500px;
      width: auto;
    }
  }

  .hero__image-wrapper {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.4s, visibility 0.4s;
    transition-delay: 0.6s;
  }

  &.on-active {
    .image-loop-wrapper {
      &.index-1 {
        animation: ${fadeIn1} 17s linear infinite;
      }
      &.index-2 {
        animation: ${fadeIn2} 17s linear infinite;
      }
      &.index-3 {
        animation: ${fadeIn3} 17s linear infinite;
      }
      &.index-4 {
        animation: ${fadeIn4} 17s linear infinite;
      }
      &.index-5 {
        animation: ${fadeIn5} 17s linear infinite;
      }
    }
    .hero__image-wrapper {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.4s, visibility 0.4s;
      z-index: -1;
    }
  }
`

const StyledHoverLink = styled.div`
  .project__link {
    display: inline-block;
  }
  .project__link a {
    font-size: 2.65rem;
    color: #000;
    text-decoration: none;
    display: inline-block;
    position: relative;
    &:after {
      content: '';
      display: block;
      width: 0;
      height: 2px;
      background: #000;
      margin-top: -10px;
    }
    &:hover::after {
      width: 100%;
    }
  }
`

const StyledHeroImageWrapper = styled.div`
  position: fixed;
  background-color: white;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -2;

  ${props => {
    if (props.position === 'Full Screen') {
      return css`
        padding: 0;
      `
    }
    if (props.position === 'Left Padding') {
      return css`
        padding-left: 25vw;
      `
    }
    if (props.position === 'Right Padding') {
      return css`
        padding-right: 25vw;
      `
    }
    if (props.position === 'Centered with padding') {
      return css`
        padding-left: 15vw;
        padding-right: 15vw;
      `
    }
  }}
`

const StyledHeroImage = styled.div`
  background-image: url(${props => getImageSizeUrl(props.bgImage, 600)});
  ${mq(1)`
    background-image: url(${props => getImageSizeUrl(props.bgImage, 1000)});
  `}
  ${mq(2)`
    background-image: url(${props => getImageSizeUrl(props.bgImage, 1400)});
  `}
  ${mq(3)`
    background-image: url(${props => getImageSizeUrl(props.bgImage, 2000)});
  `}
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  width: 100%;
  height: 100%;
`

function HoverLink(props) {
  const { linkID, name, slug, heroImage, hoverImages, heroImagePosition } = props

  return (
    <StyledHoverLink>
      <div
        className="project__link"
        onMouseOver={() => {
          const hoverImages = document.querySelector(`#images-${linkID}`)
          addClass(hoverImages, 'on-active')
        }}
        onMouseOut={() => {
          const hoverImages = document.querySelector(`#images-${linkID}`)
          removeClass(hoverImages, 'on-active')
        }}
      >
        <Link to={`/projects/${slug}`}>{name}</Link>
      </div>
      <StyledImagesLoop className="project__hoverImages" id={`images-${linkID}`}>
        {heroImage && (
          <StyledHeroImageWrapper
            position={heroImagePosition}
            className="hero__image-wrapper"
          >
            <StyledHeroImage
              bgImage={get(heroImage, 'fields.file')}
              position={heroImagePosition}
            />
          </StyledHeroImageWrapper>
        )}
        {hoverImages.map((img, idx) => {
          const title = get(img, 'fields.title')
          const imageFile = get(img, 'fields.file')
          const key = get(img, 'sys.id')

          return (
            <div className={`image-loop-wrapper index-${idx + 1}`} key={key}>
              <ResponsiveImage
                className={`image-loop`}
                image={imageFile}
                title={title}
              />
            </div>
          )
        })}
      </StyledImagesLoop>
    </StyledHoverLink>
  )
}

export default HoverLink
