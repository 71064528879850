import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { get, sample } from 'lodash-es'
import styled from 'styled-components'
import AppCache from '../../services/cache'
import contentful, { ENTRIES } from '../../services/contentful'
import HamburgerIcon from './HamburgerIcon'
import { withRouter } from 'react-router'
import { mqMax } from '../../utils/mq'

const StyledMobileHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 24px 0 20px 18px;
  z-index: 99999;
  position: fixed;
  top: 0;

  .header__item__logo {
    a {
      font-size: 1.5rem;
      color: #000000;
      transition: color 0.5s;
    }
    &.active a {
      color: #ffffff;
      transition: color 0.5s;
    }
  }
`

const StyledMobileNav = styled.div`
.mobile__nav{
	position: fixed;
	opacity: 0;
	width: 100%;
	visibility: hidden;
	height: 100vh;
	text-align: left;
	padding: 0rem 1.8rem;
  z-index: 99998;
  font-size: 2.25rem;
  top: 0;
  color: #ffffff;
  transition: opacity 1s ease, visibility 0.6s ease;
  transition-delay: 0.3s;

  a {
    color: #ffffff;
  }

	.overflow-nav-container {
		transition: all 0.1s ease;
    opacity: 0;
    margin-top: 17vh;
    padding: 2rem 0;
    line-height: 1.25;
	}

	&.active {
		visibility: visible;
		opacity: 1;
		transition: transform 0.4s ease, opacity 0.4s ease;
		transform: translateY(0);
		.overflow-nav-container {
			transition: all 0.4s ease 0.4s;
			transition-delay: 0.3s;
			opacity: 1;
			transform: translateY(-10px);
		}
  }

`

function HeaderMobile(props) {
  const { pathname } = props.location
  const [footerLinks, setFooterLinks] = useState([])
  const [navVisible, setNavVisible] = useState(false)

  function setBodyColor() {
    const bgColors = ['#f04e30', '#8eafdc', '#17a186', '#ef76a4', '#f8b518']
    const mobile__nav = document.querySelector('.mobile__nav')
    mobile__nav.style.backgroundColor = sample(bgColors)
  }

  async function fetchFooterLinks() {
    if (!AppCache.get('footerLinks')) {
      const res = await contentful.getEntryById(ENTRIES.footerLinks)
      const data = get(res, 'fields.footerLinks', [])

      setFooterLinks(data)
      AppCache.set('footerLinks', data, 10000)
    } else {
      setFooterLinks(AppCache.get('footerLinks'))
    }
  }

  function closeMobileNavigation() {
    setNavVisible(false)
  }

  // Set and reset background color
  useEffect(() => {
    setBodyColor()
  }, [])

  useEffect(() => {
    fetchFooterLinks()
  }, [])

  return (
    <div className="header__mobile">
      <StyledMobileHeader>
        <HamburgerIcon
          navVisible={navVisible}
          onToggleNav={() => {
            if (navVisible) {
              setNavVisible(false)
              window.setTimeout(() => {
                setBodyColor()
              }, 1000)
            } else {
              setNavVisible(true)
            }
          }}
        />
        <div
          className={`header__item__logo ${navVisible ? 'active' : ''} ${
            pathname === '/' ? 'page-home' : ''
          }`}
        >
          <Link to="/">Famous Charm</Link>
        </div>
      </StyledMobileHeader>
      <StyledMobileNav>
        <div className={`mobile__nav ${navVisible ? 'active' : ''}`}>
          <div className="overflow-nav-container">
            <div className="nav__item">
              <Link to={`/projects`} onClick={() => setNavVisible(false)}>
                Projects
              </Link>
            </div>
            <div className="nav__item">
              <Link to={`/info`} onClick={() => setNavVisible(false)}>
                Info
              </Link>
            </div>
            <div className="nav__item">-</div>
            {footerLinks.map(link => {
              return (
                <div className="nav__item" key={get(link, 'fields.link')}>
                  <a
                    href={get(link, 'fields.link', '')}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {get(link, 'fields.title')}
                  </a>
                </div>
              )
            })}
          </div>
        </div>
      </StyledMobileNav>
    </div>
  )
}

export default withRouter(HeaderMobile)
