import { css } from 'styled-components'
import defaultTheme from 'reakit-theme-default'
import { mqMax } from './mq'

const theme = {
  ...defaultTheme,

  palette: {
    ...defaultTheme.palette,
    primary: ['#fef035', '#F1E9E0', 'lightblue'],
  },

  Button: css`
    ${defaultTheme.Button};
    color: #222;
    background: white;
    transition: background 0.3s ease;
    &:hover {
      color: white;
      background: #222;
    }
  `,

  Heading: css`
    font-weight: bold;
    margin: 1em 0 0.5em;

    &:first-child {
      margin-top: 0;
    }
    h1& {
      font-size: 4rem;
      line-height: 1.1;
      ${mqMax(1)` font-size: 2.75rem; `}
    }
    h2& {
      font-size: 3.5rem;
      line-height: 1.2;
      ${mqMax(1)` font-size: 2.45rem; `}
    }

    h3& {
      font-size: 2.4rem;
      line-height: 1.1;
      font-weight: 400;
      letter-spacing: -1px;
      ${mqMax(1)` font-size: 1.5rem; `}
    }

    h4& {
      font-size: 1.5rem;
      line-height: 1.45;
      ${mqMax(1)` font-size: 1.25rem; `}
    }
    h5& {
      font-size: 1.25em;
    }
    h6& {
      font-size: 0.95em;
    }
  `,

  Link: css`
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  `,
}

export default theme
