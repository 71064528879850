import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { keyframes } from 'styled-components'
import { isMobile } from '../../utils/helpers'
import HeaderMobile from './HeaderMobile'
import { mq } from '../../utils/mq'

const jumb = keyframes`
  0% {
    transform: rotateX(0);
    animation-timing-function: cubic-bezier(0.02, 0.01, 0.21, 1);
  }
  50% {
    transform: rotateX(180deg);
    animation-timing-function: cubic-bezier(0.3, 0.27, 0.07, 1.64);
  }
  100% {
    transform: rotateX(360deg);
  }
`

const circle = keyframes`
  0% {
    transform: scale(00);
    animation-timing-function: cubic-bezier(0.02, 0.01, 0.21, 1);
    opacity: 1;
  }
  100% {
    transform: scale(300);
    animation-timing-function: cubic-bezier(0.3, 0.27, 0.07, 1.64);
    opacity: 1;
  }
`

const StyledHeader = styled.div`
  a {
    font-size: 1.6rem;
    color: #000;
    &:hover {
      text-decoration: underline;
    }
  }

  .header__item__projects {
    position: fixed;
    top: 2rem;
    left: 3rem;
    z-index: 999;
    &.is-active {
      text-decoration: underline;
    }
  }

  .header__item__logo {
    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 2;
    &.page-home {
      position: fixed;
    }
  }

  .header__item__info {
    position: fixed;
    top: 2rem;
    right: 3rem;
    z-index: 999;
    &.is-active {
      text-decoration: underline;
    }
  }

  .yellow__circle {
    height: 1px;
    width: 1px;
    background-color: #fffb00;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: -20px;
    right: 0;
    opacity: 0;
    z-index: 1 !important;
  }

  .header__item__info span {
    z-index: 999;
    position: relative;
  }

  .header__item__info:hover span {
    display: inline-block;
    animation: ${jumb} 1s ease-in-out both;
  }

  .header__item__info:hover .yellow__circle {
    display: inline-block;
  }

  .header__item__projects:hover span {
    display: inline-block;
    animation: ${jumb} 1s ease-in-out both;
  }
`

const StyledWrappedHeader = styled.div`
  .header__mobile {
    display: block;
    ${mq(1)`
      display: none;
    `}
  }
  .header__desktop {
    display: none;
    ${mq(1)`
      display: block
  `}
  }
`

function Header(props) {
  const { pathname } = props.location

  return (
    <StyledWrappedHeader>
      <HeaderMobile />
      <StyledHeader id="app__header" className="header__desktop">
        <div
          className={`header__item__projects ${
            pathname === '/projects' ? 'is-active' : ''
          }`}
        >
          <Link to="/projects">
            <span>P</span>
            <span>r</span>
            <span>o</span>
            <span>j</span>
            <span>e</span>
            <span>c</span>
            <span>t</span>
            <span>s</span>
          </Link>
        </div>
        <div
          className={`header__item__logo ${pathname === '/' ? 'page-home' : ''}`}
        >
          <Link to="/">Famous Charm</Link>
        </div>
        <div
          className={`header__item__info ${
            pathname === '/info' ? 'is-active' : ''
          }`}
        >
          <Link to="/info" className="info__link">
            <span>I</span>
            <span>n</span>
            <span>f</span>
            <span>o</span>
          </Link>
          <div className="yellow__circle"></div>
        </div>
      </StyledHeader>
    </StyledWrappedHeader>
  )
}

export default withRouter(Header)
