import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { get, find, findIndex } from 'lodash-es'
import { Box, Grid } from 'reakit'
import contentful, { ENTRIES } from '../../services/contentful'
import MetaTags from '../../components/MetaTags'
import ResponsiveImage from '../../components/ResponsiveImage'
import GridContainer from '../../components/GridContainer'
import Container from '../../components/Container'
import Ratio from '../../components/Ratio'
import StyledBgImage from '../../components/BackgroundImage'
import AppCache from '../../services/cache'
import { scrollTo } from '../../utils/helpers'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'
import { mq, mqMax } from '../../utils/mq'
import { keyframes } from 'styled-components'

const jumb = keyframes`
0% {
  -webkit-transform: rotateX(0);
          transform: rotateX(0);
          animation-timing-function: cubic-bezier(0.02, 0.01, 0.21, 1);
}
50% {
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
          animation-timing-function: cubic-bezier(0.3, 0.27, 0.07, 1.64);
}
100% {
  -webkit-transform: rotateX(360deg);
          transform: rotateX(360deg);
}
`

const StyledContainer = styled.div`
  padding-top: 5rem;
  opacity: 0;
  &.is-active {
    transition: opacity 0.4s;
    opacity: 1;
  }

  .project__intro {
    padding: 2rem 3rem;
    ${mqMax(0)`
      padding: 0.5rem 1rem 2rem;
    `}
    h1 {
      text-align: center;
      font-family: Robinson;
      font-weight: 400;
      font-size: 2.25rem;
      padding-bottom: 0;
      margin-bottom: 0.6rem;
      ${mqMax(0)`
        font-size: 2rem;
        margin-bottom: 0.1rem;
      `}
    }
  }

  .project__type {
    text-align: center;
    margin-bottom: 0;
    font-size: 1.15rem;
  }

  .project__introText {
    text-align: center;
    font-size: 2.25rem;
    padding: 6rem;
    ${mqMax(0)`
      font-size: 1.6rem;
      padding: 3rem 1rem;
    `}
  }

  h3 {
    padding: 2.25rem 0 0.5rem;
  }

  .project__about {
    line-height: 1.5rem;
    margin-bottom: 4rem;
    ${mq(1)`
      margin-bottom: 10rem;
    `}

    .credit__title {
      font-weight: 700;
    }
  }

  .link__to-top {
    text-decoration: underline;
    font-size: 1rem;
    margin-top: 1rem;
    font-weight: 700;
    ${mqMax(0)`
    display: none;
    `}
    &:hover {
      text-decoration: none;
    }
  }

  .link__to-top:hover span {
    display: inline-block;
    animation: ${jumb} 1s ease-in-out both;
  }

  .navigation {
    text-align: right;
    color: #000000;
    ${mqMax(0)`
    font-size: 1.15rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    `}
    h3 {
      font-weight: 400 !important;
      display: inline-block;
      color: #000000;
    }
    .next {
      &::before {
        content: '|';
        padding: 0 0.5rem;
        ${mqMax(0)`
        content: none;
        padding: 0;
        `}
      }
    }
  }
`

function Project({ match }) {
  const slug = get(match, 'params.id')
  const [pageData, setPageData] = useState(null)
  const [prevCaseStudy, setPrevCaseStudy] = useState(null)
  const [nextCaseStudy, setNextCaseStudy] = useState(null)
  const [pageLoaded, setPageLoaded] = useState(false)

  async function fetchProjectsData() {
    if (!AppCache.get('pageProjects')) {
      const response = await contentful.getEntryById(ENTRIES.projectsPage)
      const data = get(response, 'fields', {})
      AppCache.set('pageProjects', data, 10000)

      return data
    } else {
      return AppCache.get('pageProjects')
    }
  }

  async function fetchProjectEntries() {
    if (!AppCache.get('projectEntries')) {
      const response = await contentful.getProjectEntries()
      const data = get(response, 'items', [])
      AppCache.set('projectEntries', data, 10000)

      return data
    } else {
      return AppCache.get('projectEntries')
    }
  }

  async function fetchPageData() {
    const projectsData = await fetchProjectsData()
    const projectEntries = await fetchProjectEntries()

    const caseStudiesGrouped = get(projectsData, 'caseStudyNavigation', []).concat(
      get(projectsData, 'categoryNavigation', []),
    )

    const caseStudies = caseStudiesGrouped.map(c => {
      return find(projectEntries, ['sys.id', get(c, 'sys.id')], {})
    })

    const currentCaseStudyIndex = findIndex(caseStudies, ['fields.slug', slug])

    const prevCaseStudy =
      caseStudies[
        currentCaseStudyIndex - 1 >= 0
          ? currentCaseStudyIndex - 1
          : caseStudies.length - 1
      ]

    const nextCaseStudy =
      caseStudies[
        currentCaseStudyIndex + 1 === caseStudies.length
          ? 0
          : currentCaseStudyIndex + 1
      ]

    setPrevCaseStudy(prevCaseStudy)
    setNextCaseStudy(nextCaseStudy)

    if (!AppCache.get(slug)) {
      const data = find(caseStudies, ['fields.slug', slug])
      setPageData(data)
      AppCache.set(slug, data, 10000)
    } else {
      setPageData(AppCache.get(slug))
    }
  }

  function scrollToHeader() {
    document.body.scrollTop = document.documentElement.scrollTop = 0
    // const elmt = document.querySelector('#app__header')
    // elmt.scrollIntoView({
    //   behavior: 'auto',
    // })
  }

  function resetFadeAfterNewPage() {
    setPageLoaded(false)
    setTimeout(() => {
      scrollToHeader()
      setPageLoaded(true)
    }, 400)
  }

  useEffect(() => {
    setPageLoaded(true)
  }, [])

  useEffect(() => {
    fetchPageData()
    resetFadeAfterNewPage()
  }, [slug])

  if (!pageData) {
    return null
  }

  const caseStudyName = get(pageData, 'fields.name', '')
  const client = get(pageData, 'fields.client', '')
  const year = get(pageData, 'fields.year', '')
  const aboutTitle = get(pageData, 'fields.aboutTitle', '')
  const aboutText = get(pageData, 'fields.aboutText', '')
  const introText = get(pageData, 'fields.introText', '')
  const heroImage = get(pageData, 'fields.heroImage', null)
  const imageLayouts = get(pageData, 'fields.imageLayouts', [])
  const roles = get(pageData, 'fields.roles', '')

  const prevCaseStudySlug = get(prevCaseStudy, 'fields.slug')
  const nextCaseStudySlug = get(nextCaseStudy, 'fields.slug')

  return (
    <StyledContainer className={`project ${pageLoaded ? 'is-active' : ''}`}>
      <MetaTags
        title={`${caseStudyName} - Famous Charm`}
        url={`/projects/${slug}`}
        ogImage={get(heroImage, 'fields.file.url')}
        description={introText}
      />

      <Container className="project__intro" id="project__intro">
        <h1>{caseStudyName}</h1>
        {roles && <div className="project__type">{roles}</div>}
      </Container>

      <Fade delay={800}>
        <Ratio ratio="16/9">
          <StyledBgImage>
            <ResponsiveImage
              width="50%"
              image={get(heroImage, 'fields.file')}
              title={get(heroImage, 'fields.title')}
            />
          </StyledBgImage>
        </Ratio>
      </Fade>
      {introText && (
        <Container>
          <div className="project__introText">{introText}</div>
        </Container>
      )}

      <div className="project__imageLayouts">
        {imageLayouts.map(layout => {
          const image = get(layout, 'fields.image.fields.file')
          const name = get(layout, 'fields.name')
          const imageLayout = get(layout, 'fields.layout')
          const paddingTop = get(layout, 'fields.paddingAbove')
          const paddingBottom = get(layout, 'fields.paddingBelow')
          const key = get(layout, 'sys.id')

          switch (imageLayout) {
            case 'Padding Right':
              return (
                <Container key={key}>
                  <GridContainer>
                    <Grid.Item
                      className="grid__item"
                      column="col-start 1 / span 8"
                      paddingTop={paddingTop}
                      paddingBottom={paddingBottom}
                    >
                      <Fade>
                        <ResponsiveImage image={image} title={name} width="100%" />
                      </Fade>
                    </Grid.Item>
                  </GridContainer>
                </Container>
              )
            case 'Padding Left':
              return (
                <Container key={key}>
                  <GridContainer>
                    <Grid.Item
                      className="grid__item"
                      column="col-start 5 / span 8"
                      paddingTop={paddingTop}
                      paddingBottom={paddingBottom}
                    >
                      <Fade>
                        <ResponsiveImage image={image} title={name} width="100%" />
                      </Fade>
                    </Grid.Item>
                  </GridContainer>
                </Container>
              )
            case 'Centered with Padding':
              return (
                <Container key={key}>
                  <GridContainer>
                    <Grid.Item
                      className="grid__item"
                      column="col-start 3 / span 8"
                      paddingTop={paddingTop}
                      paddingBottom={paddingBottom}
                    >
                      <Fade>
                        <ResponsiveImage image={image} title={name} width="100%" />
                      </Fade>
                    </Grid.Item>
                  </GridContainer>
                </Container>
              )

            default:
              return (
                <Box
                  key={key}
                  className="layout-fullwidth"
                  paddingTop={`${paddingTop}px`}
                  paddingBottom={paddingBottom}
                >
                  <Fade>
                    <ResponsiveImage image={image} title={name} width="100%" />
                  </Fade>
                </Box>
              )
          }
        })}
      </div>
      <div className="project__about">
        <Container>
          <GridContainer>
            {aboutText && (
              <Grid.Item className="grid__item" column="col-start 1 / span 6">
                <h3>{aboutTitle}</h3>
                <div className="project__aboutText">{aboutText}</div>
                <br />
                {client && (
                  <div>
                    <span className="credit__title">Client:</span> {client}
                  </div>
                )}
                {roles && (
                  <div>
                    <span className="credit__title">Category:</span> {roles}
                  </div>
                )}
                {year && (
                  <div>
                    <span className="credit__title">Year:</span> {year}
                  </div>
                )}
                <br />
              </Grid.Item>
            )}
            <Grid.Item
              className="grid__item navigation"
              column="col-start 7 / span 6"
            >
              <Link to={`/projects/${prevCaseStudySlug}`}>
                <h3>Last</h3>
              </Link>
              <Link to={`/projects/${nextCaseStudySlug}`}>
                <h3 className="next">Next</h3>
              </Link>
            </Grid.Item>
          </GridContainer>
          <Box
            className="link__to-top"
            textDecoration="underline"
            cursor="pointer"
            onClick={e => {
              scrollTo(e, '#app__header')
            }}
          >
            <span>T</span>
            <span>o</span>
            <span>&nbsp;</span>
            <span>T</span>
            <span>h</span>
            <span>e</span>
            <span>&nbsp;</span>
            <span>T</span>
            <span>o</span>
            <span>p</span>
          </Box>
        </Container>
      </div>
    </StyledContainer>
  )
}

export default Project
