import React from 'react'
import { string } from 'prop-types'
import { Helmet } from 'react-helmet'

const defaultDescription =
  'Famous Charm Creative Studio - Visual People specializing in Branding, Design and Creative Direction in Portland, Oregon'
const defaultOGURL = 'https://famouscharm.com'
const defaultOGImage = '/famous-charm-meta.jpg'

const MetaTags = props => (
  <Helmet>
    <meta charSet="UTF-8" />
    <title>{props.title || 'Famous Charm'}</title>
    <meta name="keywords" content="creative agency, creativity, collaboration" />
    <meta name="description" content={props.description || defaultDescription} />
    <meta itemProp="image" content={props.ogImage || defaultOGImage} />

    <meta property="og:url" content={props.url || defaultOGURL} />
    <meta property="og:type" content="Website" />
    <meta property="og:title" content={props.title || 'Famous Charm'} />
    <meta property="og:site_name" content="Famous Charm" />
    <meta
      property="og:description"
      content={props.description || defaultDescription}
    />
    <meta property="og:image" content={props.ogImage || defaultOGImage} />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />

    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:title" content={props.title || 'Famous Charm'} />
    <meta
      property="twitter:description"
      content={props.description || defaultDescription}
    />
    <meta
      property="twitter:site"
      content={props.url ? `${defaultOGURL}/${props.url}` : defaultOGURL}
    />
    <meta
      property="twitter:url"
      content={props.url ? `${defaultOGURL}/${props.url}` : defaultOGURL}
    />
    <meta property="twitter:image" content={props.ogImage || defaultOGImage} />
  </Helmet>
)

MetaTags.propTypes = {
  title: string,
  description: string,
  url: string,
  ogImage: string,
}

export default MetaTags
