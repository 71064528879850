import React, { useEffect, useState } from 'react'
import ResponsiveImage from '../../components/ResponsiveImage'
import { get } from 'lodash-es'
import { Box } from 'reakit'
import styled, { keyframes } from 'styled-components'
import { getRandomNumberBetween } from '../../utils/helpers'
import Fade from 'react-reveal/Fade'

const jumb = keyframes`
0% { -webkit-transform:translateY(0px); transform:translateY(0px) }
50% { -webkit-transformm:translateY(-8px); transform:translateY(-8px) }
100% { -webkit-transform:translateY(0px); transform:translateY(0px) }
`

const StyledAboutHoverLink = styled.span`

white-space: nowrap;
color: #0000ff;
font-family: Robinson;

  .animation2 span {
    display: inline-block;
    white-space: nowrap;
    font-weight: 700;
  }

  .animation2:hover span {
    display: inline-block;
    animation: ${jumb} .8s infinite ease-in-out both;
  }

  .animation2 span:nth-child(1) {
    animation-delay: 0s;
  }
  .animation2 span:nth-child(2) {
    animation-delay: 0.05s;
  }
  .animation2 span:nth-child(3) {
    animation-delay: 0.1s;
  }
  .animation2 span:nth-child(4) {
    animation-delay: 0.15s;
  }
  .animation2 span:nth-child(5) {
    animation-delay: 0.2s;
  }
  .animation2 span:nth-child(6) {
    animation-delay: 0.25s;
  }
  .animation2 span:nth-child(7) {
    animation-delay: 0.3s;
  }
  .animation2 span:nth-child(8) {
    animation-delay: 0.35s;
  }
  .animation2 span:nth-child(9) {
    animation-delay: 0.4s;
  }
  .animation2 span:nth-child(10) {
    animation-delay: 0.45s;
  }
  .animation2 span:nth-child(11) {
    animation-delay: 0.5s;
  }
  .animation2 span:nth-child(12) {
    animation-delay: 0.55s;
  }
  .animation2 span:nth-child(13) {
    animation-delay: 0.6s;
  }
  .animation2 span:nth-child(14) {
    animation-delay: 0.65s;
  }
  .animation2 span:nth-child(15) {
    animation-delay: 0.7s;
  }
  .animation2 span:nth-child(16) {
    animation-delay: 0.75s;
  }
  .animation2 span:nth-child(17) {
    animation-delay: 0.8s;
  }

  .image__hover {
    position: fixed;
    z-index: -1;
    display: none;
    max-width: 40vw;
    max-height: 40vh;
  }

  .animation2:hover {
    .image__hover {
      display: block;
    }
    color: #0000ff;
  }

  .animation2:visited {
    color: #0000ff;
  }


  img {
    width: auto;
    height: auto;
    max-width: 800px;
    max-height: 800px;
    min-width: 250px;
  }

`

const AboutHoverLink = ({ text }) => {
  const [imagePosition, setImagePosition] = useState({})

  const setImagePositionWithinViewport = () => {
    const width = window.innerWidth
    const height = window.innerHeight

    const rightLimit = (width * 70) / 100
    const bottomLimit = (height * 10) / 100

    setImagePosition({
      left: getRandomNumberBetween(0, rightLimit),
      top: getRandomNumberBetween(0, bottomLimit),
    })
  }

  const linkText = get(text, 'fields.title', '')
  const linkUrl = get(text, 'fields.link', '')
  const imageOnHover = get(text, 'fields.imageOnHover', {})
  const linkTextChunks = linkText.split('')

  return (
    <>
      <StyledAboutHoverLink>
        <a
          className="animation2"
          href={linkUrl}
          onMouseEnter={() => setImagePositionWithinViewport()}
          target="_blank"
          rel="noopener noreferrer"
        >
          {linkTextChunks.map(c => {
            if (c === ' ') {
              return <span>&nbsp;</span>
            }
            return <span>{c}</span>
          })}
          <Box
            className="image__hover"
            left={imagePosition.left}
            top={imagePosition.top}
          >
            <Fade>
              <ResponsiveImage
                image={get(imageOnHover, 'fields.file')}
                title={get(imageOnHover, 'fields.title')}
              />
            </Fade>
          </Box>
        </a>
      </StyledAboutHoverLink>
    </>
  )
}

export default AboutHoverLink
