import React from 'react'
import { node } from 'prop-types'
import styled from 'styled-components'
import { mqMax } from '../../utils/mq'

const StyledContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: calc(100vw - 17rem);
  width: 100%;

  ${mqMax(0)`
		max-width: calc(100vw - 3rem);
  `}

  ${mqMax(2)`
  max-width: calc(100vw - 3rem);
  `}

  ${mqMax(4)`
  max-width: calc(100vw - 3rem);
  `}

`

/* eslint-disable react/forbid-prop-types */
const propTypes = {
  children: node.isRequired,
}

const Container = props => (
  <StyledContainer {...props}>{props.children}</StyledContainer>
)

Container.propTypes = propTypes
export default Container
