import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { spring, AnimatedSwitch, AnimatedRoute } from 'react-router-transition'
import MetaTags from './components/MetaTags'
import { Provider } from 'reakit'
import { get } from 'lodash-es'
import theme from './utils/theme'
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './pages/Home'
import Projects from './pages/Projects'
import Project from './pages/Project'
import Preview from './pages/Preview'
import Info from './pages/Info'
import NotFound from './pages/NotFound'
import AppCache from './services/cache'
import ReactGA from 'react-ga'

import contentful, { ENTRIES } from './services/contentful'

import { createGlobalStyle } from 'styled-components'

const trackingId = 'UA-145753201-3' // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId)

const GlobalStyle = createGlobalStyle`
  a {
    text-decoration: none;
  }

  .route-wrapper {
    position: relative;
  }

  .route-wrapper > div {
    position: absolute;
    width: 100vw;
  }
`

const history = createBrowserHistory()

// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }) // Update the user's current page
  ReactGA.pageview(location.pathname) // Record a pageview for the given page
})

console.log(history)

// we need to map the `scale` prop we define below
// to the transform style property
function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    transition: `opacity 0.2s`,
  }
}

// child matches will...
const pageTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    opacity: 0,
  },
  // leave in a transparent, downscaled state
  atLeave: {
    opacity: 0,
  },
  // and rest at an opaque, normally-scaled state
  atActive: {
    opacity: 1,
  },
}

function App() {
  async function preFetchPageData() {
    if (!AppCache.get('pageProjects') || !AppCache.get('pageInfo')) {
      const pageProjects = contentful.getEntryById(ENTRIES.projectsPage)
      const pageAbout = contentful.getEntryById(ENTRIES.aboutPage)
      const footerLinks = contentful.getEntryById(ENTRIES.footerLinks)
      const pageHome = contentful.getEntryById(ENTRIES.homePage)

      const res = await Promise.all([
        pageProjects,
        pageAbout,
        footerLinks,
        pageHome,
      ])

      AppCache.set('pageProjects', get(res, '[0].fields'), 10000)
      AppCache.set('pageInfo', get(res, '[1].fields'), 10000)
      AppCache.set('footerLinks', get(res, '[2].fields.footerLinks'), 10000)
      AppCache.set('pageHome', get(res, '[3].fields'), 10000)
    }
  }

  useEffect(() => {
    preFetchPageData()
  }, [])

  return (
    <Provider theme={theme}>
      <Router history={history}>
        <GlobalStyle />
        <Header />
        <div className="app__container">
          <MetaTags />
          <AnimatedSwitch
            atEnter={pageTransition.atEnter}
            atLeave={pageTransition.atLeave}
            atActive={pageTransition.atActive}
            mapStyles={mapStyles}
            className="route-wrapper"
          >
            <Route path="/" exact component={Home} />
            <Route path="/projects" exact component={Projects} />
            <Route path="/info" component={Info} />
            <Route path="/projects/:id" component={Project} />
            <Route path="/preview" component={Preview} />
            <Route component={NotFound} />
          </AnimatedSwitch>
        </div>
        <Footer />
      </Router>
    </Provider>
  )
}

export default App
