import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import contentful, { ENTRIES } from '../../services/contentful'
import Container from '../../components/Container'
import MetaTags from '../../components/MetaTags'
import { Box } from 'reakit'
import { get, sample } from 'lodash-es'
import AboutHoverLink from './AboutHoverLink'
import AppCache from '../../services/cache'
import styled from 'styled-components'
import { mqMax } from '../../utils/mq'

const StyledContainer = styled.div`

margin-top: 28vh;
font-size: 3rem;
margin-bottom: 10rem;

  ${mqMax(3)`
    margin-top: 32vh;
    font-size: 2.25rem;
  `}

  ${mqMax(0)`
  margin-top: 15vh;
  font-size: 1.5rem;
  `}

  ${mqMax(1)`
  margin-top: 18vh;
  font-size: 1.65rem;
  `}

  ${mqMax(4)`
  margin-top: 18vh;
  font-size: 1.5rem;
  `}

}

`

const StyledProjectsFeed = styled.div`
  columns: 2 200px;
  column-gap: 1rem;
  padding: 1rem;
  margin: 2rem 0 4rem;

  .single__feed {
    margin: 2rem 0;
    display: inline-block;
    width: 100%;
    font-size: 2.25rem;
    a {
      color: #000;
    }
  }

  .projects__title {
    font-weight: 400;
  }

  .project__name {
    margin-top: 1rem;
  }
`

const BG_COLORS = ['#f04e30', '#8eafdc', '#17a186', '#ef76a4', '#f8b518']

function Info() {
  const [pageData, setPageData] = useState(null)
  const [caseStudies, setCaseStudies] = useState([])
  const [bgColor, setBgColor] = useState(null)

  async function fetchPageData() {
    if (!AppCache.get('pageInfo')) {
      const response = await contentful.getEntryById(ENTRIES.infoPage)
      const data = get(response, 'fields', {})

      setPageData(data)
      AppCache.set('pageInfo', data, 10000)
    } else {
      setPageData(AppCache.get('pageInfo'))
    }
  }

  async function fetchProjectsData() {
    if (!AppCache.get('caseStudies')) {
      const response = await contentful.getProjectEntries()
      const data = get(response, 'items', [])

      setCaseStudies(data)
      AppCache.set('caseStudies', data, 10000)
    } else {
      setCaseStudies(AppCache.get('caseStudies'))
    }
  }

  useEffect(() => {
    fetchPageData()
    fetchProjectsData()
    setBgColor(sample(BG_COLORS))
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }, [])

  if (!pageData) {
    return null
  }

  const aboutText = get(pageData, 'aboutText', [])
  const address = get(pageData, 'address')
  const email = get(pageData, 'email')
  const phone = get(pageData, 'phone')

  return (
    <>
      <MetaTags title={'Info - Famous Charm'} url="info" />
      <Box
        className="background-color"
        background={bgColor}
        position="fixed"
        width="100vw"
        height="100vh"
        zIndex="-1"
      />
      <Box>
        <Container>
          <StyledContainer>
            <div className="info__about">
              {aboutText.map(text => {
                const textType = get(text, 'sys.contentType.sys.id')
                if (textType === 'aboutLinks') {
                  return (
                    <span>
                      {' '}
                      <span className="info__link-text">
                        <AboutHoverLink text={text} />
                      </span>{' '}
                      {get(text, 'fields.addBreakAfterText') && (
                        <span>
                          <br /> <br />
                        </span>
                      )}
                    </span>
                  )
                }

                return (
                  <span className="info__normal-text">
                    {get(text, 'fields.aboutText')}
                    {get(text, 'fields.addBreakAfterText') && (
                      <span>
                        <br /> <br />
                      </span>
                    )}
                  </span>
                )
              })}
            </div>
            {/*
          <Box className="info__company" marginTop="2rem">
            <div>Send: {email}</div>
            <div>Phone: {phone}</div>
            <div>Address: {address}</div>
          </Box>
          */}
          </StyledContainer>
        </Container>
      </Box>

      {/*      <Box textAlign="center" marginTop="6rem" fontSize="1.6rem">
        {caseStudies && caseStudies.length > 0 && <span>Projects</span>}
      </Box>
      <StyledProjectsFeed className="projects__feed">
        {caseStudies.map(project => {
          const heroImage = get(project, 'fields.heroImage.fields.file', '')
          const projectName = get(project, 'fields.name', '')
          const projectSlug = get(project, 'fields.slug', '')

          return (
            <Box className="single__feed">
              <Link to={`projects/${projectSlug}`}>
                <ResponsiveImage title={projectName} image={heroImage} />
                <Box className="project__name">{projectName}</Box>
              </Link>
            </Box>
          )
        })}
      </StyledProjectsFeed> */}
    </>
  )
}

export default Info
