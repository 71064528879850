import React from 'react'
import { styled, Block } from 'reakit'

const RatioWrapper = styled(Block)`
  && {
    height: 0;
    overflow: hidden;
    padding-bottom: ${props => `calc(100% * 1 / (${props.ratio}))`};
    position: relative;
  }
`

const RatioContent = styled(Block)`
  && {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

const Ratio = props => (
  <RatioWrapper {...props}>
    <RatioContent>{props.children}</RatioContent>
  </RatioWrapper>
)
export default Ratio
