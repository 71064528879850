import React, { useEffect, useState } from 'react'
import { forEach, get, sample } from 'lodash-es'
import styled from 'styled-components'
import { Box } from 'reakit'
import AppCache from '../../services/cache'
import contentful, { ENTRIES } from '../../services/contentful'
import Sketcher from './Sketcher'
import { Link } from 'react-router-dom'
import { mqMax } from '../../utils/mq'

const StyledHomePage = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .arrow__link {
    position: fixed;
    bottom: 32px;
    right: 32px;
    z-index: 99999;
    border-radius: 50%;
    background: #ffffff;
    width: 42px;
    height: 42px;
    display: none;
    ${mqMax(0)` display: block; `}
    img {
      position: relative;
      left: 12px;
      top: 11px;
    }
  }
  #canvas {
    opacity: 0;
    &.is-active {
      transition: opacity 0.4s;
      opacity: 1;
    }
  }
`

const BG_COLORS = ['#f04e30', '#8eafdc', '#17a186', '#ef76a4', '#f8b518']

function Home() {
  const [firstSketchLoaded, setFirstSketchLoaded] = useState(true)
  const [sketchInstance, setSketchInstance] = useState(null)
  const [pageData, setPageData] = useState(null)
  const [currentBrushIndex, setCurrentBrushIndex] = useState(null)
  const [preloadImages, setPreloadImages] = useState([])
  const [bgColor, setBgColor] = useState(null)
  const [canvasLoaded, setCanvasLoaded] = useState(false)

  async function fetchPageData() {
    if (!AppCache.get('pageHome')) {
      const response = await contentful.getEntryById(ENTRIES.homePage)
      const data = get(response, 'fields', {})
      AppCache.set('pageHome', data, 10000)
      setPageData(data)

      // Set the initial sketcher image
      const firstImage = get(data, 'draggableImages[0].fields.file', {})
      initiateSketcher(firstImage)

      // Preload all images
      preloadSketchImages(get(data, 'draggableImages', []))
      setCurrentBrushIndex(0)
    } else {
      const data = AppCache.get('pageHome')
      setPageData(data)

      // Set the initial sketcher image
      const firstImage = get(data, 'draggableImages[0].fields.file', {})
      initiateSketcher(firstImage)

      // Preload all images
      preloadSketchImages(get(data, 'draggableImages', []))
      setCurrentBrushIndex(0)
    }
  }

  function preloadSketchImages(images) {
    const loadedImages = []

    forEach(images, img => {
      const brushImageWidth = get(img, 'fields.file.details.image.width', 0)
      const brushImageHeight = get(img, 'fields.file.details.image.height', 0)

      const brushWidthAndHeight = getBrushWidthAndHeight(
        brushImageWidth,
        brushImageHeight,
      )

      const image = new Image()
      image.src = get(img, 'fields.file.url')
      image.width = brushWidthAndHeight.width
      image.height = brushWidthAndHeight.height

      loadedImages.push(image)
    })

    setPreloadImages(loadedImages)
  }

  function getBrushWidthAndHeight(width, height) {
    const windowWidth = window.innerWidth
    const brushRatio = window.Modernizr.touch ? 0.45 : 0.28

    const imageRatio = width / height

    const brushWidth =
      brushRatio * windowWidth * imageRatio > 0.5 * windowWidth
        ? brushRatio * windowWidth
        : brushRatio * windowWidth * imageRatio
    const brushHeight = brushWidth / imageRatio

    return {
      width: brushWidth,
      height: brushHeight,
    }
  }

  function initiateSketcher(firstImage) {
    const brushImageUrl = get(firstImage, 'url', '')
    const brushImageWidth = get(firstImage, 'details.image.width', 0)
    const brushImageHeight = get(firstImage, 'details.image.height', 0)

    const brushWidthAndHeight = getBrushWidthAndHeight(
      brushImageWidth,
      brushImageHeight,
    )

    const brush = new Image()
    brush.src = brushImageUrl
    brush.width = brushWidthAndHeight.width
    brush.height = brushWidthAndHeight.height

    const sketcher = new Sketcher('canvas', brush)
    setTimeout(() => {
      setCanvasLoaded(true)
      sketcher.start()
      setSketchInstance(sketcher)
    }, 300)
  }

  function updateSketchImage() {
    const draggableImages = get(pageData, 'draggableImages')
    if (!draggableImages) {
      return
    }

    const nextBrushIndex =
      currentBrushIndex + 1 === draggableImages.length ? 0 : currentBrushIndex + 1

    const brush = preloadImages[nextBrushIndex]
    sketchInstance.updateCanvasBrush(brush)
    setCurrentBrushIndex(nextBrushIndex)
  }

  // Set and reset background color
  useEffect(() => {
    fetchPageData()
    setBgColor(sample(BG_COLORS))
  }, [])

  return (
    <StyledHomePage className="homepage">
      <Box
        className="background-color"
        background={bgColor}
        position="fixed"
        width="100vw"
        height="100vh"
        zIndex="-1"
      />
      <canvas
        id="canvas"
        width="600"
        height="600"
        style={{ width: '600px', height: '600px' }}
        className={canvasLoaded ? 'is-active' : ''}
        onClick={() => {
          if (!window.Modernizr.touch) {
            updateSketchImage()
          }
        }}
        onTouchStart={() => {
          if (!firstSketchLoaded) {
            updateSketchImage()
          } else {
            setFirstSketchLoaded(false)
          }
        }}
      />
      <div className="arrow__link">
        <Link to="/projects">
          <img src="arrow.svg" alt="Go to Projects" />
        </Link>
      </div>
    </StyledHomePage>
  )
}

export default Home
