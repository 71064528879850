import React from 'react'
import styled from 'styled-components'
import { mqMax } from '../../utils/mq'

const StyledGridContainer = styled.div`
  && {
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    ${mqMax(1)`
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      .grid__item {
        grid-column: auto;
        grid-row: auto;
      }
    `};
  }
`

const GridContainer = props => (
  <StyledGridContainer gap="10px" {...props}>
    {props.children}
  </StyledGridContainer>
)

export default GridContainer
