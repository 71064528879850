import React, { useEffect, useState } from 'react'
import { sample } from 'lodash-es'
import styled from 'styled-components'
import { Box } from 'reakit'
import Sketcher from '../Home/Sketcher'
import { mqMax } from '../../utils/mq'
import MetaTags from '../../components/MetaTags'

const StyledHomePage = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .arrow__link {
    position: fixed;
    bottom: 32px;
    right: 32px;
    z-index: 99999;
    border-radius: 50%;
    background: #ffffff;
    width: 42px;
    height: 42px;
    display: none;
    ${mqMax(0)` display: block; `}
    img {
      position: relative;
      left: 12px;
      top: 11px;
    }
  }
  #canvas {
    opacity: 0;
    &.is-active {
      transition: opacity 0.4s;
      opacity: 1;
    }
  }
`

const BG_COLORS = ['#f04e30', '#8eafdc', '#17a186', '#ef76a4', '#f8b518']

function Home() {
  const [firstSketchLoaded, setFirstSketchLoaded] = useState(true)
  const [sketchInstance, setSketchInstance] = useState(null)
  const [pageData, setPageData] = useState(null)
  const [currentBrushIndex, setCurrentBrushIndex] = useState(null)
  const [preloadImages, setPreloadImages] = useState([])
  const [bgColor, setBgColor] = useState(null)
  const [canvasLoaded, setCanvasLoaded] = useState(false)

  function getBrushWidthAndHeight(width, height) {
    const windowWidth = window.innerWidth
    const brushRatio = window.Modernizr.touch ? 0.45 : 0.28

    const imageRatio = width / height

    const brushWidth =
      brushRatio * windowWidth * imageRatio > 0.5 * windowWidth
        ? brushRatio * windowWidth
        : brushRatio * windowWidth * imageRatio
    const brushHeight = brushWidth / imageRatio

    return {
      width: brushWidth,
      height: brushHeight,
    }
  }

  function initiateSketcher() {
    const brushWidthAndHeight = getBrushWidthAndHeight(564, 349)

    const brush = new Image()
    brush.src = '/404.png'
    brush.width = brushWidthAndHeight.width
    brush.height = brushWidthAndHeight.height

    const sketcher = new Sketcher('canvas', brush)
    setTimeout(() => {
      setCanvasLoaded(true)
      sketcher.start()
      setSketchInstance(sketcher)
    }, 300)
  }

  // Set and reset background color
  useEffect(() => {
    setBgColor(sample(BG_COLORS))
    initiateSketcher()
  }, [])

  return (
    <StyledHomePage className="homepage">
      <MetaTags title={'Page Not Found - Famous Charm'} />
      <Box
        className="background-color"
        background={bgColor}
        position="fixed"
        width="100vw"
        height="100vh"
        zIndex="-1"
      />
      <canvas
        id="canvas"
        width="600"
        height="600"
        style={{ width: '600px', height: '600px' }}
        className={canvasLoaded ? 'is-active' : ''}
      />
    </StyledHomePage>
  )
}

export default Home
