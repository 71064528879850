import React from 'react'
import { styled } from 'reakit'

const StyledBgImage = styled.div`
  position: relative;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    min-width: 50%;
    min-height: 50%;
  }
`

const BackgroundImage = props => (
  <StyledBgImage {...props}>{props.children}</StyledBgImage>
)

export default BackgroundImage
