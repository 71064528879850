import React from 'react'
import styled from 'styled-components'
import { toggleClass } from '../../utils/classie'

const StyledCloseIcon = styled.div`
  #menuToggle {
    cursor: pointer;
    padding: 10px 18px 4px 10px;
    span {
      display: block;
      width: 21px;
      height: 2px;
      margin-bottom: 5px;
      position: relative;
      background: #000;
      border-radius: 0px;
      z-index: 1;
      transform-origin: 4px 0px;
      transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
    }
    span:first-child {
      transform-origin: 0% 0%;
    }
    span:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
    span:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
  }

  #menuToggle.active {
    span {
      opacity: 1;
      transform: rotate(45deg) translate(-7px, -9px);
      background: #232323;
      background: #ffffff;
    }
    span:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }
    span:nth-last-child(2) {
      transform: rotate(-45deg) translate(-3px, 7px);
      background: #ffffff;
    }
  }
`

function HamburgerIcon(props) {
  return (
    <StyledCloseIcon>
      <div
        id="menuToggle"
        className={`${props.navVisible ? 'active' : ''}`}
        onClick={e => {
          props.onToggleNav(e)
        }}
      >
        <span />
        <span />
        <span />
      </div>
    </StyledCloseIcon>
  )
}

export default HamburgerIcon
