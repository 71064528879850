import React, { useEffect, useState } from 'react'
import { forEach, get } from 'lodash-es'
import styled from 'styled-components'
import { Box, Block } from 'reakit'
import HoverLink from './HoverLink'
import contentful, { ENTRIES } from '../../services/contentful'
import ResponsiveImage from '../../components/ResponsiveImage'
import MetaTags from '../../components/MetaTags'
import AppCache from '../../services/cache'
import { mq, mqMax } from '../../utils/mq'
import { addClass, removeClass } from '../../utils/classie'
import { getImageSizeUrl } from '../../utils/helpers'

const StyledProjects = styled.div`
  padding: 22vh 3rem 4rem 3rem;
  line-height: 1.5;
  position: fixed;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100vw;
  top: 0;
  ${mqMax(0)`
    padding: 20vh 1.8rem 8rem 1.5rem;
  `}
  .separate-line {
    h4 {
      font-size: 1.35rem;
      margin: 3rem 0 0.4rem 0;
      font-family: Robinson;
    }
  }
  .separate-line.case-study {
    h4 {
      margin-top: 0;
    }
  }

  .default-bg-image {
    display: none;
    ${mq(1)`
      display: block;
    `}
  }

  .mobile__animated-case-study-image {
    max-width: auto !important;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    &.active {
      opacity: 1;
      visibility: visible;
    }
    ${mq(1)`
      display: none;
    `}
  }
`

const StyledBgProjectImage = styled.div`
  position: fixed;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  z-index: -3;
  overflow: hidden;
  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    min-width: 50%;
    min-height: 50%;
  }
`

const StyledBackgroundImage = styled(Box)`
  background-image: url(${props => getImageSizeUrl(props.bgImage, 600)});
  ${mq(1)`
    background-image: url(${props => getImageSizeUrl(props.bgImage, 1000)});
  `}
  ${mq(2)`
    background-image: url(${props => getImageSizeUrl(props.bgImage, 1400)});
  `}
  ${mq(3)`
    background-image: url(${props => getImageSizeUrl(props.bgImage, 2000)});
  `}
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`

const SWITCH_BG_DURATION = 2000

function Projects() {
  const [pageData, setPageData] = useState(null)

  // Fetch page data
  async function fetchPageData() {
    if (!AppCache.get('pageProjects')) {
      const response = await contentful.getEntryById(ENTRIES.projectsPage)
      const data = get(response, 'fields', {})

      setPageData(data)
      AppCache.set('pageProjects', data, 10000)

      window.setTimeout(() => {
        startBgLooping()
      }, 100)
    } else {
      setPageData(AppCache.get('pageProjects'))
      window.setTimeout(() => {
        startBgLooping()
      }, 100)
    }
  }

  function startBgLooping() {
    const mobileAnimatedBg = document.querySelectorAll(
      '.mobile__animated-case-study-image',
    )

    for (let i = 0; i < 1000; i++) {
      window.setTimeout(() => {
        addClass(mobileAnimatedBg[i % mobileAnimatedBg.length], 'active')
      }, i * SWITCH_BG_DURATION)
      window.setTimeout(() => {
        removeClass(mobileAnimatedBg[i % mobileAnimatedBg.length], 'active')
      }, i * SWITCH_BG_DURATION + SWITCH_BG_DURATION)
    }
  }

  // Prefetching the sub page data and put in cache
  const prefetchSubPageData = async () => {
    const allProjects = await contentful.getProjectEntries()
    forEach(get(allProjects, 'items', []), proj => {
      AppCache.set(get(proj, 'fields.slug', ''), proj, 10000)
    })
  }

  useEffect(() => {
    fetchPageData()
    prefetchSubPageData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledProjects>
      <MetaTags title="Projects - Famous Charm" url="projects" />
      <StyledBgProjectImage>
        <ResponsiveImage
          className={'default-bg-image'}
          image={get(pageData, 'initialSiteBackgroundImage.fields.file')}
          title={get(pageData, 'initialSiteBackgroundImage.fields.title')}
        />
      </StyledBgProjectImage>
      <Block
        className={`mobile__animated-case-study-image index-0`}
        backgroundSize="cover"
        backgroundImage={`url(${get(
          pageData,
          'initialSiteBackgroundImage.fields.file.url',
        )})`}
        backgroundPosition="center center"
        backgroundRepeat="no-repeat"
      />
      {get(pageData, 'caseStudyNavigation', []).length > 0 && (
        <div className="separate-line case-study">
          <h4>CASE STUDIES</h4>
        </div>
      )}
      {get(pageData, 'caseStudyNavigation', []).map((c, index) => {
        const name = get(c, 'fields.name', '')
        const slug = get(c, 'fields.slug', '')
        const hoverImages = get(c, 'fields.homepageImagesOnHover', [])
        const heroImage = get(c, 'fields.heroImage')
        const key = get(c, 'sys.id')
        const heroImagePosition = get(c, 'fields.initialBackgroundImagePosition')
        const heroImageUrl = get(heroImage, 'fields.file.url', '')

        return (
          <div key={key}>
            <StyledBackgroundImage
              className={`mobile__animated-case-study-image index-${index + 1}`}
              bgImage={get(heroImage, 'fields.file', {})}
            />
            <HoverLink
              linkID={key}
              name={name}
              slug={slug}
              heroImagePosition={heroImagePosition}
              hoverImages={hoverImages}
              heroImage={heroImage}
            />
          </div>
        )
      })}

      {get(pageData, 'categoryNavigation', []).length > 0 && (
        <div className="separate-line">
          <h4>ONGOING</h4>
        </div>
      )}

      {get(pageData, 'categoryNavigation', []).map((c, index) => {
        const name = get(c, 'fields.name', '')
        const slug = get(c, 'fields.slug', '')
        const hoverImages = get(c, 'fields.homepageImagesOnHover')
        const key = get(c, 'sys.id')
        const heroImage = get(c, 'fields.heroImage')
        const heroImagePosition = get(c, 'fields.initialBackgroundImagePosition')
        const heroImageUrl = get(heroImage, 'fields.file.url', '')
        const bgIndex = get(pageData, 'caseStudyNavigation', []).length + index + 1

        return (
          <div key={key}>
            <StyledBackgroundImage
              className={`mobile__animated-case-study-image index-${bgIndex}`}
              bgImage={get(heroImage, 'fields.file', {})}
            />
            <HoverLink
              linkID={key}
              name={name}
              slug={slug}
              heroImage={heroImage}
              hoverImages={hoverImages}
              heroImagePosition={heroImagePosition}
            />
          </div>
        )
      })}
    </StyledProjects>
  )
}

export default Projects
